import React, { useEffect, useState, useRef } from "react";
import { useSelector } from 'react-redux';
import { Row, Col, Form, Button } from 'react-bootstrap';
//import Layout from '../HorizontalLoayout/Layout';
import toast, { Toaster } from "react-hot-toast";
//import PhoneIcon from '../../assets/images/support_page/phone-icon.svg'
import SupportIcon from '../../assets/images/support_page/support-email.svg'
import locatiion from '../../assets/images/support_page/support-location.svg'
import axios from "axios";
import { GET_WORK_CATEGORY, POST_SUPPORT_REQUEST } from "../../api/uri_helper";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Draggable from 'react-draggable';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import support_img from "../../assets/images/support_page/Support QR.png"
import KeyboardIcon from '@mui/icons-material/Keyboard';
import SupportUploadImage from "../UserLogin/SupportUploader";
import { Player } from "video-react";
import "video-react/dist/video-react.css";
import documentuploadicon from "../../assets/images/support_page/documentuploadicon.png";
import Suppport_Open from "../../assets/images/support_page/support-open.svg";
import attachment_icon from "../../assets/images/support_page/attachment-icon.svg"
import mike_icon from "../../assets/images/support_page/mike-icon.svg"
import need_help from "../../assets/images/support_page/need-help-img.svg"
import send_icon from "../../assets/images/support_page/send-icon.svg"
import Suppport_Close from "../../assets/images/support_page/support-close.svg"
import side_msg from "../../assets/images/support_page//side-msg-icon.svg"
import side_phone from "../../assets/images/support_page/side-phone-icon.svg"
import setting from "../../assets/images/support_page/settings.svg"
import profile_pic from "../../assets/images/support_page/profile__new.png"
import PhoneIcon from "../../assets/images/support_page/support-phone.svg";
//import SupportIcon from "../Assests/Images/support-email.svg";
//import locatiion from "../Assests/Images/support-location.svg";
import document_icon from "../../assets/images/support_page/document.png";
import video_icon from "../../assets/images/support_page/video-camera.png";
import image_icon from "../../assets/images/support_page/gallery.png";
import mike_off from "../../assets/images/support_page/mic_off.svg";
import close_container_icon from "../../assets/images/support_page/delete-media-icon.svg";
import close_icon from "../../assets/images/support_page/close-bot.svg";
import PhoneInput, { getCountries, getCountryCallingCode, formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import heic2any from 'heic2any';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css'; 

const SupportRequest = ({ supportModalDisplay, setSupportModalDisplay, iconColor }) => {
    const [hoverColor, setHoverColor] = useState();
    const [workCategory, setWorkCategory] = useState([]);
    const [workCategoryId, setWorkCategoryId] = useState();
    const [message, setMessage] = useState("");
    const [messageDisplay, setMessageDisplay] = useState(false);
    const [messageErr, setMessageErr] = useState(false);
    const [workCategoryErr, setWorkCategoryErr] = useState(false);
    const [displayState, setDisplayState] = useState(false);
    const [phnumber, setPhNumber] = useState('');
    const [selectedCountryCode, setSelectedCountryCode] = useState();
    const [phoneError, setPhoneError] = useState(false);
    const [requestType, setRequestType] = useState(true);
    const [touched, setTouched] = useState(false);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [attacmentOptionsDisplay, setAttacmentOptionsDisplay] = useState(false);
    const [closeSupportModal, setCloseSupportModal] = useState(false);
    const [uploadedImage, setUploadedImage] = useState(null);
    const [images, setImages] = useState({
        upload: {
            pictures: [],
            maxFileSize: 5242880,
            imgExtension: [".jpg", ".png", ".jpeg"],
            defaultImages: [
            ]
        }
    })
    const [mediaTypeSelected, setMediaTypeSelected] = useState(false);
    let extension, name, fileTypeId;
    const [fileTypeID, setFileTypeID] = useState();
    const [imageName, setImageName] = useState('');
    const [uploadedChatBotImage, setUploadedChatBotImage] = useState([]);
    const [uploadedChatBotVideo, setUploadedChatBotVideo] = useState([]);
    const [uploadedChatBotVoice, setUploadedChatBotVoice] = useState("");
    const [uploadedChatBotDoc, setUploadedChatBotDoc] = useState([]);
    const [recordingStarted, setRecordingStarted] = useState("");
    const [videoMedia, setVideoMedia] = useState([]);
    const [videoFileName, setVideoFileName] = useState("");
    const [pdfMedia, setPdfMedia] = useState("");
    const [fileName, setFileName] = useState("");
    const [documnentName, setDocumentName] = useState('');
    const [audioRecording, setAudioRecording] = useState('');
    const [recordedFileName, setRecordedFileName] = useState('')
    const inputVideoRef = useRef();
    const inputDocRef = useRef();
    const audioRef = useRef(null);
    const [recordingDuration, setRecordingDuration] = useState(0);
    //  const [supportModalDisplay, setSupportModalDisplay] = useState(false);
    // const allUserReducer = useSelector((state) => state.userReducer.userData);
    //const profilePicture = allUserReducer[0]?.profilePicture ?? profile_pic;
    const [webmBlobUrl, setWebmBlobUrl] = useState(null);
    const [audioStream, setAudioStream] = useState(null);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [userName, setUserName] = useState();
    const [emailID, setEmailID] = useState(localStorage.getItem('emailid'));
    const [inputString, setInputString] = useState("");
    const [isClicked, setIsClicked] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [recordingControlState, setRecordingControlState] = useState(false);
    const [textMessageErr, setTextMessageErr] = useState('');
    const [textErrorMessage, setTextErrorMessage] = useState('');
  
    const [textareaDisable, setTextareaDisable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [heicFile, setHeicFile] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState("US");
    let url, audio, fileType;
    const [audioBlob, setAudioBlob] = useState(null);
    const [docFileName, setDocFileName] = useState([]);
    const [iskeyboardClicked, setIsKeyboardClicked] = useState(false);
    const [openqrcode, setOpenQrCode] = useState(false);
    const [openkeyboard, setOpenKeyboard] = useState(false);
    const [keyboardVisible, setKeyboardVisible] = useState(false);
    const [alphakeyboardVisible, setAlphaKeyboardVisible] = useState(false);
    const [input, setInput] = useState('');

    const toggleSupportModal = () => {
        setUploadedChatBotDoc('');
        setUploadedChatBotImage('');
        setUploadedChatBotVideo('');
        setUploadedChatBotVoice('')
        setAttacmentOptionsDisplay(false);
        setSupportModalDisplay(prevState => !prevState);
    };

    const CompanyId = 15;
    const siteId = 1;
    const attachmentOptionsRef = useRef(null);
    const [allUploadedFiles, setAllUploadedFiles] = useState([]);
    let uploadedFileNames;

    useEffect(() => {
        if (!supportModalDisplay) {
            setAllUploadedFiles([]);
            setUploadedChatBotDoc([]);
            setUploadedChatBotImage([]);
            setUploadedChatBotVideo([]);
            setAllUploadedFiles([]);
        }
    }, [supportModalDisplay])

    const handleFileType = (fileTypeExtension) => {
        if (fileTypeExtension === '.jpg' || fileTypeExtension === '.jpeg' || fileTypeExtension === '.png' || fileTypeExtension === '.gif' || fileTypeExtension === '.svg' || fileTypeExtension === '.heic') {
            fileTypeId = 1
        }
        else if (fileTypeExtension === '.mp4' || fileTypeExtension === '.MOV' || fileTypeExtension === '.mov' || fileTypeExtension === '.wmv' || fileTypeExtension === '.avi') {
            fileTypeId = 2
        }
        else if (fileTypeExtension === '.pdf') {
            fileTypeId = 3
        }
        else if (fileTypeExtension === '.mp3' || fileTypeExtension === '.webm') {
            fileTypeId = 4
        }
        else {

        }
        //   setFileTypeID(fileTypeId)
        return fileTypeId
    }

    const onAlphaKeyboardChange = (input) => {
        setInputString(input)
        setMessage(input)

    }

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            phnumber: "",
            workCategoryId: "",
            message: "",
        },

        onSubmit: (values) => {
            if (requestType) {
                if (!phoneError && !textMessageErr) {
                    setSubmitButtonDisabled(true);
                    handleRequest(values);
                    setIsDisabled(true);
                }
                else if (phnumber && !inputString) {
                    setTextMessageErr(true);
                }
                else if (!phnumber && inputString) {
                    setPhoneError(true);
                }
                else {
                    setPhoneError(true);
                    setTextMessageErr(true);
                }
            }
            else {
                if (!textMessageErr) {
                    setSubmitButtonDisabled(true);
                    handleRequest(values);
                }
                else {
                    setTextMessageErr(true);
                    setTextErrorMessage("Please describe the Request");
                }

            }
        }
    });

    const handleRequestSubmit = (e) => {
        e.preventDefault();
        validation.handleSubmit();
        if (requestType) {
            if (!phnumber && !inputString) {
                setPhoneError(true);
                setTextMessageErr(true);
                setTextErrorMessage("Describe the Request");
            }

            else if (!inputString) {
                setTextMessageErr(true);
                setTextErrorMessage("Describe the Request");
            }
            else if (!phnumber || !(phnumber && phnumber.length > 10)) {
                setPhoneError(true);
                //  return;
            }
            else {
                setSubmitButtonDisabled(true);
                setTextareaDisable(true);
                handleDisplayState();
            }
        }
        else {
            if (!inputString) {
                setTextMessageErr(true);
                setTextErrorMessage("Describe the Request");
                return
            }
            else {
                setTextareaDisable(true);
                setSubmitButtonDisabled(true);
                handleDisplayState();
            }
        }

        return false;
    }

    const handleDisplayState = () => {
        try {
            if (requestType) { // If requestType is true, i.e., "Request for Call" option is selected
                if (phnumber && phnumber.length > 0) { // Check if phone number field is filled and valid
                    setDisplayState(true);
                    setPhoneError(false); // Reset phone error state
                } else {
                    setPhoneError(true); // Set phone error state
                    setDisplayState(false);
                }
            } else {
                // If requestType is false, i.e., "Send an Email" option is selected
                if (!textMessageErr) {
                    setDisplayState(true);
                } else {
                    setDisplayState(false);
                }
            }
        } catch (error) {
            console.error("Error handling display state:", error);
        }
    };

    const handleCategory = async () => {
        try {
            const workCategoryResponse = await axios.get(GET_WORK_CATEGORY);
            setWorkCategory(workCategoryResponse.data.data);
        } catch (error) {
            console.error("Error fetching work categories:", error);
        }
    };

    const handleRequest = async (event) => {
        const formData = new FormData();
        uploadedFileNames = new Set();
        if (!Array.isArray(allUploadedFiles)) {
            console.error('allUploadedFiles is not an array');
            return;
        }

        allUploadedFiles && allUploadedFiles.forEach((fileObj, index) => {
            if (fileObj.Extension === '.heic') {

                const _fileData = new File([fileObj.AttachmentFile], fileObj.AttachmentFile.name);
                if (!uploadedFileNames.has(_fileData.name)) {
                    setImageName(_fileData.name);
                    formData.append(`SupportAttachments[${index}].AttachmentFile`, _fileData);
                    formData.append(`SupportAttachments[${index}].FileTypeId`, fileObj.FileTypeId);
                    uploadedFileNames.add(_fileData.name);
                }
                setHeicFile(false);
            } else if (fileObj.Extension === '.jpg' || fileObj.Extension === '.jpeg' || fileObj.Extension === '.png' || fileObj.Extension === '.gif' || fileObj.Extension === '.svg') {

                const imageUrl = fileObj.AttachmentFile.split(";");
                var contentType = imageUrl[0].split(":")[1];
                var realData = imageUrl[2].split(",")[1];
                var blob = b64toBlob(realData, contentType);
                const _fileData = new File([blob], imageUrl[1].substring(5));
                if (!uploadedFileNames.has(_fileData.name)) {
                    setImageName(_fileData.name);
                    formData.append(`SupportAttachments[${index}].AttachmentFile`, _fileData);
                    formData.append(`SupportAttachments[${index}].FileTypeId`, fileObj.FileTypeId);
                    uploadedFileNames.add(_fileData.name);
                }
            } else if (fileObj.Extension === '.mp4' || fileObj.Extension === '.MOV' || fileObj.Extension === '.mov' || fileObj.Extension === '.wmv' || fileObj.Extension === '.avi') {
                const videoData = new File([new Blob([fileObj.AttachmentFile])], fileObj.name);
                if (!uploadedFileNames.has(videoData.name)) {
                    formData.append(`SupportAttachments[${index}].AttachmentFile`, videoData);
                    formData.append(`SupportAttachments[${index}].FileTypeId`, fileObj.FileTypeId);
                    uploadedFileNames.add(videoData.name);
                }
            } else if (fileObj.Extension === '.pdf') {
                const pdfBlob = new Blob([fileObj.pdfFile]);
                const pdfData = new File([pdfBlob], fileObj.AttachmentFile);
                if (!uploadedFileNames.has(pdfData.name)) {
                    formData.append(`SupportAttachments[${index}].AttachmentFile`, pdfData);
                    formData.append(`SupportAttachments[${index}].FileTypeId`, fileObj.FileTypeId);
                    uploadedFileNames.add(pdfData.name);
                }
            } else if (fileObj.Extension === '.mp3' || fileObj.Extension === '.webm') {
                // const recordedData = new File([new Blob([audioRecording])], fileObj.AttachmentFile);
                // if (!uploadedFileNames.has(recordedData.name)) {
                //     formData.append(`SupportAttachments[${index}].AttachmentFile`, recordedData);
                //     formData.append(`SupportAttachments[${index}].FileTypeId`, fileObj.FileTypeId);
                //     uploadedFileNames.add(recordedData.name);
                // }

                const recordedData = new File([audioBlob], fileObj.AttachmentFile)
                if (!uploadedFileNames.has(recordedData.name)) {
                    formData.append(`SupportAttachments[${index}].AttachmentFile`, recordedData);
                    formData.append(`SupportAttachments[${index}].FileTypeId`, 4);
                    uploadedFileNames.add(recordedData.name);
                }
            } else {
                console.log('no file uploaded');
            }
        });


        // formData.append("EmailId", emailID && emailID);
        // formData.append("CompanyId", companyId && companyId);
        //  formData.append("SiteId", siteId && siteId);
        formData.append("EmailId", emailID && emailID);
        formData.append("CompanyId", 12);
        formData.append("SiteId", 1);
        formData.append("appID", 1);
        formData.append("isCallRequest", requestType ? "true" : "false");
        formData.append("PhoneNumber", requestType ? (phnumber && phnumber) : null);
        if (workCategoryId !== null && workCategoryId !== undefined) {
            formData.append("CategoryId", workCategoryId);
        }
        // formData.append("Description", message && message);
        const trimmedMessage = inputString.trim();
        formData.append("Description", trimmedMessage && trimmedMessage);

        const response = await axios.post(POST_SUPPORT_REQUEST, formData);
        // const response = await axios.post(POST_SUPPORT_REQUEST_NEW, formData);

        if (response.data.success) {
            setImages((prev) => ({
                ...prev, upload: {
                    pictures: [], maxFileSize: 5242880,
                    imgExtension: [".jpg", ".png", ".jpeg"],
                    defaultImages: [
                    ]
                }
            }));
            uploadedFileNames.length = 0;
            setAllUploadedFiles([]);
            setMessageDisplay(true);
            setInputString('');
            setPhNumber("");
            setWorkCategoryId("");
            setMessage("");
            setUploadedChatBotDoc([]);
            setUploadedChatBotImage([]);
            setUploadedChatBotVideo([]);
            setUploadedChatBotVoice('');
            setAudioBlob(null);
            setIsKeyboardClicked(false)
            setKeyboardVisible(false)
            setAlphaKeyboardVisible(false)
            setPhoneError("")
            //  setFileTypeID('');
        } else {
            toast.success(`${response.data.message}`, {
                className: "toastError",
            });
            setSubmitButtonDisabled(false);
            setMessageDisplay(false);
        }
    }

    function b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;
        var byteCharacters = atob(b64Data);
        var byteArrays = [];
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);
            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }
        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    useEffect(() => {
        if (messageDisplay && displayState) {
            setSubmitButtonDisabled(true); // Disable the button while the message is being displayed
            const timeoutId = setTimeout(() => {
                setMessageDisplay(false);
                setTextareaDisable(false);
                setSubmitButtonDisabled(false);
                setIsDisabled(false);
                // Enable the button after the message display is finished
            }, 10000);
            return () => clearTimeout(timeoutId);
        }
    }, [messageDisplay, displayState]);

    useEffect(() => {
        handleCategory();
    }, []);

    const handleButtonClick = (type) => {
        setRequestType(type);
    };

    const handleChange = (phone, country) => {
        setPhNumber("")
        // Handle phone number changes
        if (phnumber && phnumber.length > 10) {
            setPhoneError(false);
        } else {
            setPhoneError(true);
        }
        setPhNumber(phone);
    };

    const handleCountryChange = (country) => {
        setPhNumber("")
        setSelectedCountry(country);

        // Use getCountryCallingCode to get the calling code
        const callingCode = country ? `+${getCountryCallingCode(country)}` : '';

        // Preserve existing digits after country code
        const currentDigits = phnumber.split(' ')[1] || '';

        // Set the phone number with the new country code and existing digits
        setPhNumber(`${callingCode} ${currentDigits}`);
    };

    const handleBlur = () => {
        //   handleValidation();
        setTouched(true);
    };

    const handleQRCode = () => {
        setOpenQrCode(!openqrcode);
        setOpenKeyboard(false);
        setIsKeyboardClicked(false);
        setMessage("");
    };

    const handleKeyboardClick = () => {
        setOpenKeyboard(!openkeyboard);
        setPhNumber("")
        setInputString("")
        setIsKeyboardClicked(!iskeyboardClicked);
        setOpenQrCode(false)
        setMessage("");
        setPhoneError("")
    };

    // uploader
    function base64ToBinary(base64) {
        const binaryString = atob(base64);
        const length = binaryString.length;
        const bytes = new Uint8Array(length);
        for (let i = 0; i < length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
    }

   
    const handleKeyPress = (button) => {
        if (button === "{backspace}") {
            // Handle backspace separately
            handleBackspace();
        } else {
            // Handle normal key press (numeric input)
            handleNumericInput(button);
        }
    };
    const [hasEntered, setHasEntered] = useState(false); 
    const handleBackspace = () => {
        const updatedInput = input.slice(0, -1); // Remove the last character from input
        setInput(updatedInput); // Update the input state

        // Update the phone number by stripping the last character from phnumber
        let updatedPhoneNumber = `${selectedCountry ? `+${getCountryCallingCode(selectedCountry)}` : ''}${updatedInput}`;
        setPhNumber(updatedPhoneNumber); // Update phnumber

        // Only show validation if the user has started entering digits
        if (updatedInput.length > 0) {
            validatePhoneNumber(updatedPhoneNumber);
        } else {
            setPhoneError(false); // Reset error when input is cleared
        }
    };

    const handleNumericInput = (button) => {
        const updatedInput = input + button; // Add the pressed button to input
        setInput(updatedInput); // Update input state

        // Update the phone number by combining the country code and the new input
        let updatedPhoneNumber = `${selectedCountry ? `+${getCountryCallingCode(selectedCountry)}` : ''}${updatedInput}`;
        setPhNumber(updatedPhoneNumber); // Update phnumber

        // Mark that the user has started entering digits
        setHasEntered(true);

        // Show validation only if user has entered at least one digit
        if (hasEntered) {
            validatePhoneNumber(updatedPhoneNumber);
        }
    };
  
    const validatePhoneNumber = (phoneNumber) => {
        const totalDigits = phoneNumber.replace(/\D/g, '').length; // Remove non-numeric characters and count digits
        setPhoneError(totalDigits < 10); // Update the error state based on the digit count
    };


    // Calculate size
    function calculateImageSize(base64) {
        const binaryData = base64ToBinary(base64);
        const fileSizeInBytes = binaryData.length;
        const fileSizeInKB = fileSizeInBytes / 1024; // Convert bytes to kilobytes
        const fileSizeInMB = fileSizeInKB / 1024; // Convert kilobytes to megabytes

        return {
            bytes: fileSizeInBytes,
            kilobytes: fileSizeInKB,
            megabytes: fileSizeInMB
        };
    }

    const handleImagesChange = async (files) => {

        setAttacmentOptionsDisplay(false);
        const uploadedFilesWithExtensions = [];
        let newUrl, newBlob;
        if (!files || files.length === 0) {
            setAttacmentOptionsDisplay(false);
            setErrorMessage("Select only '.jpg', '.jpeg','.svg', '.png', '.gif', 'heic' files.");
            setTimeout(() => {
                setErrorMessage('');
            }, 5000);
            return;
        }
        let extension = '';
        for (let i = 0; i < files.length; i++) {
            const file = files[i];


            if (file.type && file.type.startsWith('image/')) {
                extension = '.' + file.type.split('/')[1];
            } else if (file.name) {
                const filename = file.name;
                const match = filename.match(/\.([^.]+)$/);
                if (match && match.length > 1) {
                    extension = '.' + match[1].toLowerCase();
                }
            }

            // Check if it's a data URI
            const dataUriIndex = file.indexOf('data:');
            if (dataUriIndex === 0) {
                const nameIndex = file.indexOf('name=', dataUriIndex);
                if (nameIndex > -1) {
                    const semicolonIndex = file.indexOf(';', nameIndex);
                    if (semicolonIndex > -1) {
                        const nameSubstring = file.substring(nameIndex, semicolonIndex);
                        const dotIndex = nameSubstring.lastIndexOf('.');
                        if (dotIndex > -1) {
                            extension = nameSubstring.substring(dotIndex);
                        }
                    }
                }
            }

            const base64String = file.split(';base64,')[1];
            const size = calculateImageSize(base64String);
            const allowedExtensions = ['.jpg', '.jpeg', '.svg', '.png', '.gif', 'heic'];

            if (size.megabytes > 5) {
                setImages('');
                setAttacmentOptionsDisplay(false);
                setErrorMessage("Image size exceeds 5MB.");
                setImages('');
                setTimeout(() => {
                    setErrorMessage('');
                }, 5000);
                return;
            }
            else {


                if (extension === '.heic') {

                    setIsLoading(true);
                    const semicolonIndex = file.indexOf(';');
                    try {
                        if (semicolonIndex !== -1) {
                            const response = await fetch(file);
                            const blob = await response.blob();
                            const conversionResult = await heic2any({ blob });
                            const url = URL.createObjectURL(conversionResult);
                            const filename = file.split(';')[1].split('=')[1];
                            const newFilename = filename.replace('.heic', '.jpeg');
                            newBlob = new Blob([conversionResult], { type: 'image/jpeg' });
                            newBlob.name = newFilename;
                            newUrl = URL.createObjectURL(newBlob);
                            if (!uploadedChatBotImage.includes(filename)) {
                                // if (!uploadedFileHeic.includes(filename)) {
                                setHeicFile(true);
                                setUploadedChatBotImage(prevImages => [...prevImages, newUrl]);
                                setImages({
                                    ...images,
                                    upload: {
                                        ...images.upload,
                                        pictures: [...images.upload.pictures, newBlob],
                                    },
                                });

                                //  setUploadedFileHeic(prevFileHeic => [...prevFileHeic, filename]);
                            } else {
                                // Handle the case when the filename already exists

                                setErrorMessage("File already uploaded.", filename);
                                setTimeout(() => {
                                    setErrorMessage('');
                                }, 5000);
                                return;
                            }

                        }
                    } catch (error) {
                        console.log(error);
                    } finally {
                        setIsLoading(false);
                    }
                }

                else {
                    const blob = file;
                    if (!uploadedChatBotImage.includes(blob)) {
                        setUploadedChatBotImage(prevImages => [...prevImages, blob]);
                        setImages({
                            ...images,
                            upload: {
                                ...images.upload,
                                pictures: [...images.upload.pictures, blob],
                            },
                        });
                    }
                }

            }

            if (!Array.isArray(allUploadedFiles)) {
                console.error('allUploadedFiles is not an array');
                return;
            }


            if (extension !== '.heic') {
                if (allUploadedFiles && !allUploadedFiles.some(existingFile => existingFile.AttachmentFile === file)) {
                    const fileType = handleFileType(extension);
                    uploadedFilesWithExtensions.push({ AttachmentFile: file, FileTypeId: fileType, Extension: extension });
                }
            }
            else {
                if (allUploadedFiles && !allUploadedFiles.some(existingFile => existingFile.AttachmentFile === newBlob)) {
                    //  extension = '.heic';
                    const fileType = handleFileType(extension);
                    uploadedFilesWithExtensions.push({ AttachmentFile: newBlob, FileTypeId: fileType, Extension: extension });
                }
            }
        }

        setAllUploadedFiles(prevFiles => [...prevFiles, ...uploadedFilesWithExtensions]);

    };

    const handleImageChoose = () => {
        setAttacmentOptionsDisplay(false);
    };

    const handleVideoFileChange = (event) => {
        setAttacmentOptionsDisplay(false);
        const files = event.target.files;
        const videos = [];
        const duplicateFiles = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            // Check file size
            const fileSizeInBytes = file.size;
            const fileSizeInMB = fileSizeInBytes / (1024 * 1024);

            // If file size exceeds 10MB, show error message and return
            if (fileSizeInMB > 10) {
                setErrorMessage("Video file size exceeds 10 MB. Please upload a smaller video.");
                setTimeout(() => {
                    setErrorMessage('');
                }, 3000);
                return;
            }
            // Add file to the videos array
            videos.push(file);
        }

        const uploadedFilesWithExtensions = videos.map(videoFile => {
            const fileExtension = '.' + videoFile.name.split('.').pop();
            fileType = handleFileType(fileExtension);
            const urlForVideo = URL.createObjectURL(videoFile);
            return { AttachmentFile: videoFile, name: videoFile.name, url: urlForVideo, FileTypeId: fileType, Extension: fileExtension };
        });

        const newVideosToAdd = uploadedFilesWithExtensions.filter(video => {
            for (const existingVideo of uploadedChatBotVideo) {
                if (existingVideo.name === video.name) {
                    //   duplicateFiles.push(video.AttachmentFile);
                    return false;
                }
            }
            return true;
        });

        if (duplicateFiles.length > 0) {
            setErrorMessage(`Uploaded file(s) already exist: ${duplicateFiles.join(', ')}`);
            setTimeout(() => {
                setErrorMessage('');
            }, 3000);
        }

        setUploadedChatBotVideo(prevVideos => [...prevVideos, ...newVideosToAdd]);

        setAllUploadedFiles(prevFiles => [...prevFiles, ...newVideosToAdd]);

        event.target.value = null;
    };

    const handleVideoChoose = (event) => {
        setAttacmentOptionsDisplay(false);
        inputVideoRef.current && inputVideoRef.current.click();
    };

    const handleDocChoose = (event) => {
        setAttacmentOptionsDisplay(false);
        inputDocRef.current.click();
    };

    const handleDocFileChange = (event) => {
        setAttacmentOptionsDisplay(false);
        const duplicateFiles = [];

        const files = event.target.files;
        const maxSize = 5 * 1024 * 1024;

        const uploadedDocumentsWithExtensions = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.size <= maxSize) {
                if (file.type === 'application/pdf') {
                    setErrorMessage('');

                    const fileExtension = '.' + file.name.split('.').pop();
                    fileType = handleFileType(fileExtension);
                    const url = URL.createObjectURL(file);
                    setFileName(file.name);
                    setDocFileName(file.Name);
                    setPdfMedia(file);

                    // Push the file itself into the array
                    uploadedDocumentsWithExtensions.push({
                        AttachmentFile: file.name,
                        FileTypeId: fileType,
                        Extension: fileExtension,
                        pdfFile: file
                    });
                    setAttacmentOptionsDisplay(false);
                } else {
                    // Reset the input value if file type is invalid
                    inputDocRef.current.value = '';
                    setErrorMessage('Please select a PDF file.');
                    setAttacmentOptionsDisplay(false);
                    setTimeout(() => {
                        setErrorMessage('');
                    }, 3000);
                }
            } else {
                // Reset the input value if file size exceeds 5 MB
                inputDocRef.current.value = '';
                setErrorMessage('File size should be maximum 5 MB.');
                setAttacmentOptionsDisplay(false);
                setTimeout(() => {
                    setErrorMessage('');
                }, 3000);
            }
        }

        const newDocsToAdd = uploadedDocumentsWithExtensions.filter((doc) => {
            for (const existingDoc of uploadedChatBotDoc) {
                if (existingDoc.AttachmentFile === doc.AttachmentFile) {
                    duplicateFiles.push(doc.AttachmentFile);
                    return false;
                }
            }
            return true;
        });

        // Display error message for duplicates
        if (duplicateFiles.length > 0) {
            setErrorMessage(`Uploaded file(s) already exist: ${duplicateFiles.join(', ')}`);
            setTimeout(() => {
                setErrorMessage('');
            }, 3000);
        }

        setUploadedChatBotDoc(prevDocs => [...prevDocs, ...newDocsToAdd]);

        setAllUploadedFiles(prevFiles => [...prevFiles, ...newDocsToAdd]);
    };

    const handleImageDeletion = (type, index) => {
        switch (type) {
            case 'image':
                setUploadedChatBotImage('');
                handleRemoveImgClick(index, 'image')
                break;
            case 'video':
                setUploadedChatBotVideo('');
                handleRemoveImgClick(index, 'video')
                break;
            case 'doc':
                setUploadedChatBotDoc('');
                handleRemoveImgClick(index, 'doc')
                break;
            case 'audio':
                setUploadedChatBotVoice('');
                setAudioBlob(null);
                handleRemoveImgClick(index, 'audio')
                break;
            default:
                break;
        }
    };

    const startRecording = () => {

        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                setAudioStream(stream);

                const recorder = new MediaRecorder(stream);
                let audioChunks = [];
                setMediaRecorder(recorder)
                recorder.ondataavailable = (event) => {
                    if (event.data.size > 0) {
                        audioChunks.push(event.data);
                    }
                };

                recorder.onstop = async () => {

                    const blob = new Blob(audioChunks);
                    const fileTypes = 'mp3';
                    const fileExtension = '.mp3';
                    fileType = handleFileType(fileExtension);
                    //const uploadedAudioWithExtensions = [];

                    const timestamp = new Date().getTime();
                    const fileName = `audio_${timestamp}${fileExtension}`;

                    setRecordedFileName(fileName);

                    replaceExistingAudio(fileName, fileType, fileExtension, blob);

                };

                recorder.start();
                setIsRecording(true);
            })
            .catch(error => {
                console.error('Error accessing the microphone:', error);
            });
    };


    const stopRecording = () => {
        if (mediaRecorder && isRecording) {
            mediaRecorder.stop();
            setIsRecording(false);
            if (audioStream) {
                audioStream.getTracks().forEach(track => track.stop());
                setAudioStream(null);
            }
        }
    };

    const replaceExistingAudio = (recordedFileName, audiofileType, audioFileExtension, blob) => {
        const existingAudioIndex = allUploadedFiles.findIndex(file => file.FileTypeId === 4);
        if (existingAudioIndex !== -1) {
            allUploadedFiles[existingAudioIndex] = {
                AttachmentFile: recordedFileName,
                FileTypeId: audiofileType,
                Extension: audioFileExtension
            };
        } else {
            setAllUploadedFiles([
                ...allUploadedFiles.filter(file => file.FileTypeId !== 4), {
                    AttachmentFile: recordedFileName,
                    FileTypeId: audiofileType,
                    Extension: audioFileExtension
                }]);
        }

        url = URL.createObjectURL(blob);
        audio = document.createElement("audio");
        audio.src = url;
        audio.controls = true;

        //  setAudioURL(url);
        setUploadedChatBotVoice('');
        setUploadedChatBotVoice(url);

        audioRef.current && audioRef.current.load();
        setAudioBlob(blob);
    };



    const handleInputString = (event) => {
        const { value } = event.target;
        setInputString(value);

        if (value.trim() === "") {
            setInputString('');
            setMessage("");
        } else {
            setTextMessageErr(false);
            setTextErrorMessage("");
            handleSubmitText(value);
        }
    };

    const handleSubmitText = (inputString) => {
        let array = inputString.trim().split(/\s+/); // Split by any whitespace characters
        array = array.filter(str => str !== ""); // Remove empty strings
        let string = array.join(" ");

        if (string.trim() === "") {
            setInputString('');
            setMessage("");
        } else {
            setMessage(string);
        }
    };

    const handleRemoveImgClick = (index, mediaType) => {

        let updatedAllFilesArray = [...allUploadedFiles];

        if (mediaType === 'image') {
            const removedImage = uploadedChatBotImage[index];
            const updatedImagesArray = uploadedChatBotImage.filter((_, i) => i !== index);
            setUploadedChatBotImage(updatedImagesArray);

            updatedAllFilesArray = updatedAllFilesArray.filter(file =>
                (file.AttachmentFile !== removedImage)
            );
        } else if (mediaType === 'video') {
            const removedVideo = uploadedChatBotVideo[index];

            const updatedVideosArray = uploadedChatBotVideo.filter((_, i) => i !== index);
            setUploadedChatBotVideo(updatedVideosArray);

            updatedAllFilesArray = updatedAllFilesArray.filter(file =>
                !(file.AttachmentFile === removedVideo.AttachmentFile && file.Extension === removedVideo.Extension)
            );
        } else if (mediaType === 'doc') {
            const removedDoc = uploadedChatBotDoc[index];
            const updatedDocsArray = uploadedChatBotDoc.filter((_, i) => i !== index);
            setUploadedChatBotDoc(updatedDocsArray);

            updatedAllFilesArray = updatedAllFilesArray.filter(file =>
                !(file.AttachmentFile === removedDoc.AttachmentFile && file.Extension === removedDoc.Extension)
            );


        } else if (mediaType === 'audio') {
            // Remove the existing audio file
            setAudioBlob(null);
            setUploadedChatBotVoice(''); // Assuming uploadedChatBotAudio is the state variable holding the audio file
            updatedAllFilesArray = updatedAllFilesArray.filter(file => file.FileTypeId !== 4);
        }
        setAllUploadedFiles(updatedAllFilesArray);
    };

    const isMobileView = window.innerWidth <= 567;
    const buttonClassName = `btn open-chat p-1 ${supportModalDisplay && isMobileView ? 'mobile-hidden' : ''}`;

    return (
        <div>

            <div className="chatbot-wrap">
                {supportModalDisplay || closeSupportModal ?
                    (
                        //<Draggable
                        //    cancel=".btn.open-chat"
                        //>
                        <div className="support-chatbox">
                            <div className="border-box">
                            <div style={{ background: iconColor }} className="d-flex bot-head justify-content-between align-items-center">
                                <h4 className="head mb-0">Support</h4>
                                <button type="button" className="btn open-chat p-1"
                                        onClick={() => {
                                            setSupportModalDisplay(false);
                                            setOpenQrCode(false);
                                            setPhNumber("")
                                            setKeyboardVisible(false)
                                            setInputString("")
                                            setAlphaKeyboardVisible(false)
                                            setOpenKeyboard(false)
                                            setPhoneError(false)
                                            setIsKeyboardClicked(false);   
                                            setMessage("");
                                            setInput("")
                                        }}>
                                    <img src={close_icon}></img>
                                </button>

                            </div>

                            <div className="chatbot-container">
                                <div className="chat-sidebar">
                                    <div className="tabs">
                                        <div className="d-flex align-items-start">
                                                <div className="nav flex-column nav-pills me-2 w-100 gap-2" id="v-pills-tab" role="tablist" aria-orientation="vertical">


                                                    <button className={`nav-link ${!openqrcode ? 'active' : ''}`} id="msg-content" data-bs-toggle="pill" data-bs-target="#msg-content-toggle"
                                                        type="button" role="tab" aria-controls="msg-content-toggle" aria-selected="true">
                                                        <img src={side_msg} alt="Suppport_Message"
                                                            onClick={() => {
                                                                setOpenQrCode(false);

                                                            }}
                                                        />
                                                    </button>
                                                    <button className="nav-link" id="call-content"
                                                        data-bs-toggle="pill"
                                                        data-bs-target="#call-content-toggle"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="call-content-toggle"
                                                        aria-selected="false"
                                                    >
                                                        <img src={side_phone} alt="Suppport_Phone"
                                                            onClick={() => {
                                                                setOpenQrCode(false);

                                                            }}
                                                        />
                                                    </button>
                                                    <button className="nav-link" id="keyboard-content"
                                                        aria-selected="false" title="Open Keyboard" onClick={handleKeyboardClick}
                                                        style={{ backgroundColor: iskeyboardClicked ? '#B97A57' : '#dfdede' }}>
                                                        <KeyboardIcon style={{ fontSize: '20px', color: iskeyboardClicked ? 'white' : 'black' }} />
                                                    </button>

                                                    <button className="nav-link" id="keyboard-content" aria-selected="false" title="Open QRCode"
                                                        onClick={handleQRCode} style={{ backgroundColor: openqrcode ? '#B97A57' : '#dfdede' }}>
                                                        <QrCode2Icon style={{ fontSize: '20px', color: openqrcode ? 'white' : 'black' }} />
                                                    </button>
                                                </div>
                                        </div>
                                    </div>

                                    <div className="profile-wrap">
                                       {/* <button className="btn setting-display"><img src={setting} alt="Setting" /></button>*/}
                                        <span className="profile">                                          
                                            <img src={profile_pic} alt="Profile picture" />
                                        </span>
                                    </div>
                                </div>

                                <div className="chatbot-content">
                                    <div className="support-profile p-2 d-flex align-items-start">                                      
                                        <div className="support-profile-content">
                                            <h6>Need Help?</h6>
                                            <p className="m-0">Fill out the form below or scan QR to open in your phone and we will get back to you shortly. Please stand by.</p>
                                            </div>

                                            <div className="supportqr_img">
                                                <img src={support_img}></img>
                                            </div>
                                      
                                    </div>

                                        {openqrcode ? (
                                            <div className="qr_img">
                                                <img src={support_img}></img>
                                                <p className="image-text">Please scan the QR code for support request</p>
                                            </div>

                                        ) :

                                            <div className="tab-content" id="v-pills-tabContent">
                                                <div className="tab-pane fade show active" id="msg-content-toggle" role="tabpanel" aria-labelledby="msg-content">

                                                    <div className="msg-content-box">

                                                        <div className={`scroll-box ${uploadedChatBotImage.length > 1 || uploadedChatBotDoc.length > 1 || uploadedChatBotVideo.length > 1 ? 'multi-image' : ''}`}>


                                                            {/* addinf form */}
                                                            <Form
                                                                className="form-horizontal support-form"
                                                            >
                                                                <div className="mb-3 card p-2 requests-wrap">
                                                                    <div className="d-flex gap-2 justify-content-center">
                                                                        <Button
                                                                            // style={primaryButtonStyling}

                                                                            className={` ${requestType ? 'active' : ''}`}
                                                                            onClick={() => handleButtonClick(true)}
                                                                        >
                                                                            Request for Call
                                                                        </Button>
                                                                        <Button
                                                                            // style={primaryButtonStyling}
                                                                            className={`${!requestType ? 'active' : ''}`}
                                                                            onClick={() => handleButtonClick(false)}>
                                                                            Send an Email
                                                                        </Button>
                                                                    </div>
                                                                </div>

                                                                {requestType ? (
                                                                    <div className="form-radius bg-white p-1 mb-3">
                                                                        <div className="form-group mb-2 countrycode-drop">
                                                                            <Form.Label className="py-2">
                                                                                Phone Number <span className="required-fields"> * </span>
                                                                            </Form.Label>
                                                                            <PhoneInput
                                                                                className="custom-phone-input w-100 style-input phone-style"
                                                                                defaultCountry={selectedCountry || "US"}
                                                                                onCountryChange={handleCountryChange}
                                                                                international
                                                                                //  countryCallingCodeEditable={false}
                                                                                value={phnumber}
                                                                                //  autoFocus
                                                                                onBlur={handleBlur}
                                                                                onChange={handleChange}
                                                                                onFocus={() => {
                                                                                    setKeyboardVisible(true);
                                                                                    setAlphaKeyboardVisible(false)// Show keyboard

                                                                                }}
                                                                                disabled={isDisabled}
                                                                            />
                                                                            {touched && < small className="text-danger d-block mt-2">{!phoneError ? ' ' : 'Please enter valid phone number'}</small>}
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="form-group mb-4">
                                                                        <Form.Label>
                                                                            Category
                                                                        </Form.Label>
                                                                        <div className="">
                                                                            <Form.Select
                                                                                className="w-100 email-font"
                                                                                placeholder="Select Work Category"
                                                                                value={workCategoryId}
                                                                                onChange={(e) => {
                                                                                    const selectedValue = e.target.value;
                                                                                    if (selectedValue === "Other") {
                                                                                        setWorkCategoryId("others");
                                                                                    } else {
                                                                                        setWorkCategoryId(selectedValue);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <option>
                                                                                    Select Work Category
                                                                                </option>
                                                                                {workCategory.map((category) => (
                                                                                    <option
                                                                                        key={category.workCategoryId}
                                                                                        value={category.workCategoryId}
                                                                                    >
                                                                                        {category.workDescription}
                                                                                    </option>
                                                                                ))}
                                                                                <option value={0} >Other</option>
                                                                            </Form.Select>
                                                                        </div>
                                                                    </div>
                                                                )}




                                                                <div className="message-card mb-3">
                                                                    {uploadedChatBotImage && uploadedChatBotImage.length > 0 && uploadedChatBotImage.length < 2 ?
                                                                        <div className="imagemedia media-bubble mb-2">
                                                                            <img
                                                                                src={uploadedChatBotImage} alt="media"
                                                                            />

                                                                            <button className="position-absolute delete-btn" onClick={() => handleImageDeletion('image', 0)} disabled={submitButtonDisabled}>
                                                                                <img className="" src={close_container_icon} alt="delete" /></button>

                                                                        </div>
                                                                        : ''}

                                                                    {uploadedChatBotVideo && uploadedChatBotVideo.length > 0 && uploadedChatBotVideo.length < 2 && (
                                                                        <div className="videomedia media-bubble mb-2">
                                                                            <Player
                                                                                playsInline
                                                                                src={uploadedChatBotVideo[0].url}
                                                                                fluid={false}
                                                                            />
                                                                            <button className="position-absolute delete-btn" onClick={() => handleImageDeletion('video', 0)} disabled={submitButtonDisabled}>
                                                                                <img className="" src={close_container_icon} alt="delete" /></button>
                                                                        </div>
                                                                    )}

                                                                    {uploadedChatBotDoc && uploadedChatBotDoc.length > 0 && uploadedChatBotDoc.length < 2 && (
                                                                        <div className="docmedia media-bubble mb-2">
                                                                            <div className="text-center"><img className="botdoc-upload mb-3" src={documentuploadicon} alt="Document" width={20} height={20} />
                                                                                <p>{fileName ? fileName : ''}</p></div>
                                                                            <button className="position-absolute delete-btn" onClick={() => handleImageDeletion('doc', 0)} disabled={submitButtonDisabled}>
                                                                                <img className="" src={close_container_icon} alt="delete" /></button>
                                                                        </div>
                                                                    )}

                                                                    {uploadedChatBotVoice &&
                                                                        <div className="media-bubble mb-2">
                                                                            <audio controls className="audioChatBot mb-2" ref={audioRef} hidden={uploadedChatBotVoice ? '' : 'hidden'}>
                                                                                <source src={uploadedChatBotVoice} type="audio/mpeg" />
                                                                            </audio>
                                                                            <button className="position-absolute delete-btn" onClick={() => handleImageDeletion('audio', 0)} disabled={submitButtonDisabled}>
                                                                                <img className="" src={close_container_icon} alt="delete" /></button>
                                                                        </div>}


                                                                    {message && (uploadedChatBotImage || uploadedChatBotVideo || uploadedChatBotDoc || uploadedChatBotVoice) ?

                                                                        <div className="media-bubble ">  <p className="mb-0 w-100"> <span>{message}</span></p></div>
                                                                        :
                                                                        message && (!uploadedChatBotImage || !uploadedChatBotVideo || !uploadedChatBotDoc || !uploadedChatBotVoice) ?
                                                                            (
                                                                                <div className="msg-bubble mt-2">
                                                                                    <p className="mb-0 w-100"> <span>{message}</span></p>
                                                                                </div>
                                                                            ) : ''
                                                                    }

                                                                    {errorMessage && <p className="text-danger mt-1">{errorMessage}</p>}
                                                                    {/* </div>*/}

                                                                    {/* <div class="msg-bubble">
                                                                                            <p className="mb-0 w-100"> <span>{message}</span></p>
                                                                                        </div> */}



                                                                </div>

                                                                <div className="form-group mb-4 ">
                                                                    {messageDisplay && displayState ?
                                                                        (<div className=" messageDisplay">
                                                                            <label className="acknowlegment">We have received your request and want to assure you that we
                                                                                will address as soon as possible. Thank you for
                                                                                reaching out to us.</label> </div>) : ''}
                                                                </div>

                                                            </Form>
                                                        </div>

                                                        {/* end of form */}

                                                    </div>
                                                    <div className="type-box">
                                                        {((uploadedChatBotImage && uploadedChatBotImage.length > 1) ||
                                                            (uploadedChatBotVideo && uploadedChatBotVideo.length > 1) ||
                                                            (uploadedChatBotDoc && uploadedChatBotDoc.length > 1)) ?

                                                            <div className="multiple-media d-flex gap-2" id="custom-scroll">

                                                                {uploadedChatBotImage && uploadedChatBotImage.length > 1 && (
                                                                    uploadedChatBotImage.map((url, index) => (
                                                                        <div className="media-preview" key={index}>
                                                                            <img
                                                                                src={url}
                                                                                alt={`Image ${index}`}
                                                                            />
                                                                            <button onClick={() => handleRemoveImgClick(index, 'image')} disabled={submitButtonDisabled}>
                                                                                <img className="a" src={close_container_icon} alt="Delete" />
                                                                            </button>
                                                                        </div>
                                                                    ))
                                                                )}

                                                                {
                                                                    uploadedChatBotVideo && uploadedChatBotVideo.length > 1 && (

                                                                        uploadedChatBotVideo.map((video, index) => (
                                                                            <div className="media-preview" key={index}>
                                                                                <Player
                                                                                    key={index}
                                                                                    playsInline
                                                                                    src={video.url}
                                                                                    fluid={false}
                                                                                />
                                                                                <button onClick={() => handleRemoveImgClick(index, 'video')} disabled={submitButtonDisabled}>
                                                                                    <img className="" src={close_container_icon} alt="Delete" />
                                                                                </button>
                                                                            </div>
                                                                        ))
                                                                    )
                                                                }

                                                                {
                                                                    uploadedChatBotDoc && uploadedChatBotDoc.length > 1 && (
                                                                        uploadedChatBotDoc.map((doc, index) => (
                                                                            <div className="media-preview" key={index}>
                                                                                <div key={index} className="text-center">
                                                                                    <img className="botdoc-upload mb-3" src={documentuploadicon} alt="Document" />
                                                                                    <p>{doc.name}</p>
                                                                                </div>
                                                                                <button onClick={() => handleRemoveImgClick(index, 'doc')} disabled={submitButtonDisabled}>
                                                                                    <img className="" src={close_container_icon} alt="Delete" />
                                                                                </button>
                                                                            </div>
                                                                        ))
                                                                    )
                                                                }
                                                            </div> : ''}

                                                        {textErrorMessage ? <p className="text-danger mt-1 text-display text-center">{textErrorMessage}</p> :

                                                            <p className="mt-1 mb-0 px-2">Describe the issue you need help with :</p>
                                                        }
                                                        <div className="p-2 p-0">
                                                            <div className="typebox-left d-flex gap-2 justify-content-center w-100">
                                                                <div>
                                                                    <div>
                                                                        {!isRecording ?
                                                                            (<button className="btn mike_btn" onClick={startRecording} disabled={isRecording}><img src={mike_icon} alt='' /></button>)
                                                                            :
                                                                            (<button className="btn mike_btn" onClick={stopRecording} disabled={!isRecording}><img src={mike_off} alt='' /></button>)}
                                                                    </div>

                                                                </div>

                                                                {recordingStarted ? '' :
                                                                    <div className="position-relative attach-box">
                                                                        <ul className={attacmentOptionsDisplay ? 'show' : 'hide'} ref={attachmentOptionsRef}>
                                                                            <li onClick={handleImageChoose}>
                                                                                <img src={image_icon} alt="Image" />
                                                                                <SupportUploadImage
                                                                                    defaultImages={[]}
                                                                                    pictures={[]}
                                                                                    // {...images.upload ? images.upload : ""}
                                                                                    handleChange={handleImagesChange}
                                                                                    buttonText="Image"
                                                                                    images={false}
                                                                                />
                                                                            </li>

                                                                            <li onClick={handleVideoChoose} className="cursor-pointer">
                                                                                <input
                                                                                    key={inputVideoRef && inputVideoRef.current && inputVideoRef.current.value}
                                                                                    ref={inputVideoRef}
                                                                                    type="file"
                                                                                    onChange={handleVideoFileChange}
                                                                                    accept=".mov,.mp4,.wmv,.avi,.MOV"
                                                                                    hidden={true}
                                                                                    multiple
                                                                                />
                                                                                <img src={video_icon} alt="Video" />
                                                                                Video
                                                                            </li>

                                                                            <li onClick={handleDocChoose} className="cursor-pointer">
                                                                                <input
                                                                                    key={inputDocRef && inputDocRef.current && inputDocRef.current.value}
                                                                                    ref={inputDocRef}
                                                                                    type="file"
                                                                                    onChange={handleDocFileChange}
                                                                                    accept=".pdf"
                                                                                    hidden={true}
                                                                                    multiple
                                                                                />
                                                                                <img src={document_icon} alt="Document" />
                                                                                Document

                                                                            </li>
                                                                        </ul>
                                                                        {recordingStarted ? '' :
                                                                            <button className="btn"
                                                                                disabled={messageDisplay && displayState}
                                                                                onClick={() => setAttacmentOptionsDisplay(prevState => !prevState)}>
                                                                                <img src={attachment_icon} alt="Add attachment" />
                                                                            </button>}
                                                                    </div>
                                                                }


                                                                {!recordingStarted ?
                                                                    <>
                                                                        <textarea type="" className="form-control" placeholder="Describe the request"
                                                                            required
                                                                            value={inputString}
                                                                            disabled={textareaDisable}
                                                                            onChange={handleInputString}
                                                                            onFocus={() => {
                                                                                setAlphaKeyboardVisible(true); // Show alphabet keyboard
                                                                                setKeyboardVisible(false)
                                                                            }}

                                                                        ></textarea>

                                                                    </> : ""}

                                                                {!recordingStarted ?
                                                                    <button type="button" className="btn" disabled={submitButtonDisabled} onClick={(e) => { handleRequestSubmit(e) }}>
                                                                        <img src={send_icon} alt="Send Message" />
                                                                    </button>
                                                                    : ""}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="tab-pane fade call-tab" id="call-content-toggle" role="tabpanel" aria-labelledby="call-content">

                                                    <div className="msg-content-box call-tab-content">
                                                        <div className="right_contact">
                                                            <ul className="contact_list">
                                                                <li>
                                                                    <span className="con_icon">
                                                                        <img src={locatiion} width="24" height="24"></img>
                                                                    </span>
                                                                    <div className="con_detail">
                                                                        <b>Location</b>
                                                                        <p className="mb-0 mt-2">
                                                                            2155 Chenault Dr. Suite 510 Carrollton, TX 75006
                                                                        </p>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <span className="con_icon">
                                                                        <img src={PhoneIcon} width="24" height="24"></img>
                                                                    </span>
                                                                    <span className="con_detail">
                                                                        <b>Feel free to contact us</b>
                                                                        <a className="tel text-decoration-none" href="tel:+1 (833) 288-4835">+1 (833) 288-4835</a>
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    <span className="con_icon">
                                                                        <img src={SupportIcon} width="24" height="24"></img>
                                                                    </span>
                                                                    <span className="con_detail">
                                                                        <b>Support</b>
                                                                        <a className="mail text-decoration-none" href="mailto:sales@authentek.io">sales@authentek.io</a>
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        }
                                </div>
                                </div>

                                {keyboardVisible && openkeyboard && (
                                    <div className="keyboard-container">
                                        <Keyboard
                                            onKeyPress={handleKeyPress}
                                            // onChange={handleKeyboardChange}
                                            value={input}
                                            className="custom-keyboard"
                                            layout={{
                                                default: [
                                                    "1 2 3",
                                                    "4 5 6",
                                                    "7 8 9",
                                                    ". 0 {backspace}"
                                                ],
                                            }}
                                        />
                                    </div>
                                )}

                                {alphakeyboardVisible && openkeyboard && (
                                    <div className="keyboard-container">
                                        <Keyboard
                                            onChange={onAlphaKeyboardChange}  // Update the textarea value
                                            value={inputString}
                                            className="custom-keyboard"
                                            layout={{
                                                default: [
                                                    "q w e r t y u i o p",
                                                    "a s d f g h j k l",
                                                    "z x c v b n m",
                                                    "{space} {backspace}"
                                                ],
                                            }}
                                        />
                                    </div>
                                )}

                            </div>
                            </div>
                      //  </Draggable>
                    ) : ''
                }

            </div >
            {/* support */}

        </div >
    );
};

export default SupportRequest;


